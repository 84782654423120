import React from 'react';
// import { Route } from 'react-router';
import Seo from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
import About from 'sections/about';
import Services from 'sections/services';
import Contact from 'sections/contact';

import WebsiteData from '../../data/WebsiteData';

export default function HomePage() {
  return (
    <Layout>
      <Seo
        title={WebsiteData['SEO']['title']}
        description={WebsiteData['SEO']['description']}
      />
      <Banner />
      <About />
      <Services />
      <Contact />
    </Layout>
  );
}
