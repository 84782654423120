import React from 'react';
import { Container, Box, Image, Text, Card } from 'theme-ui';

import SectionHeading from '../components/section-heading';


// import PlanningImage from "../assets/images/planning.jpg";
// import ProcessingImage from "../assets/images/proccessing.jpg";
// import AnalysisImage from "../assets/images/analytics.jpg";


const cardsData = [
    {
        title: "Planning",
        description: "Experimental design and planning for computational biology projects",
        // imageObj: PlanningImage
    },
    {
        title: "Processing",
        description: "Processing of next-generation sequencing datasets",
        // imageObj: ProcessingImage
    },
    {
        title: "Analysis",
        description: "Integrative analysis of private and public (multi)omics datasets",
        // imageObj: AnalysisImage
    }
]

const getCards = () => {
    return cardsData.map(({ title, description, imageObj }) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // alignContent: "center",
                    // justifyContent: "center",
                    width: 345,
                    height: 345,
                    margin: "5px"
                }}
            >
                {/* <Image
                    component="img"
                    height="200"
                    src={imageObj}
                    alt={title}
                /> */}
                <Text sx={{textAlign: "center"}}>
                    <h3>{title}</h3>
                    <p>{description}</p>
                </Text>
            </Box>
        );
    })
};

const Services = () => {
    return (
        <section id="services">
            <Container
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "50px",
                    marginBottom: "50px",
                    padding: "10px",
                    // backgroundColor: "#576490"
                }}
            >
                <SectionHeading
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                    }}
                    title="Services"
                />
                <Box sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                >
                    {getCards()}
                </Box>
            </Container >
        </section >
    )
}


export default Services;