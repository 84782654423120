/** @jsx jsx */
import { rgba } from 'polished';
import { jsx, Container, Heading } from 'theme-ui';

import { Parallax } from 'react-parallax';

import WebsiteData from '../../data/WebsiteData';
import BackgroundImage from '../assets/images/background.jpg';

const Banner = () => {
  return (
    <Parallax
      bgImage={BackgroundImage}
      bgImageSizes='cover'
      strength={500}
      bgImageStyle={{
        filter: 'blur(2px)',
        // filter: 'saturate(120%)',
        filter: 'brightness(60%)'
      }}
    >
      <section id="home" sx={styles.section}>
        <Container sx={styles.container}>

          <div sx={styles.content}>
            <Heading as="h1" sx={{fontWeight: "bolder"}}>
              {WebsiteData["Banner"]["Motto"]}
            </Heading>
          </div>
        </Container>
      </section>
    </Parallax >
  );
};

export default Banner;

const styles = {
  section: {
    // backgroundColor: WebsiteData['pallette']['secondary'],
    // backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    pt: [14, null, null, null, 10, 14],
    pb: [14, 120, null, null, 10, 14],
    position: 'relative',
    zIndex: 0,
    '::before': {
      backgroundColor: 'white',
      content: `''`,
      position: 'absolute',
      height: [30, null, null, 70, 85, 120],
      bottom: 0,
      width: '100%',
      zIndex: -1,
    },
  },
  container: {
    minHeight: [null, null, null, null, null, '100vh'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // 'flex-end',
    flexDirection: 'column',
  },
  content: {
    maxWidth: [null, null, null, 570, 690],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    // '@media (max-width: 600px)': {
    marginTop: [18, null, null, null, null],
    pb: [null, null, null, null, "210px"],
    // },
    h1: {
      color: 'white',
      fontSize: [7, null, null, 12, 14],
      lineHeight: 1.35,
    },
    p: {
      color: rgba('white', 0.8),
      fontSize: [1, null, null, 2],
      marginTop: [3, null, null, 6],
    },
  },
  buttonGroup: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: [6, null, null, 8],
    position: 'relative',
    zIndex: 2,
    button: {
      px: ['12px', null, null, '18px'],
    },
  },
  btnPrimary: {},
  btnOutlined: {
    borderColor: rgba('white', 0.25),
    color: 'white',
    ml: 3,
    svg: {
      ml: 2,
    },
  },
  illustration: {
    transform: ['scale(1.20)', null, null, 'none'],
    alignItems: 'center',
    display: 'flex',
    marginTop: [2, null, null, -4, -5],
  },
};
